.input-range__text-steps {
  label {
    width: 33px;
  }
}

/************  Genral Media  ************/

@media only screen and (max-width: 900px) {
  // popup-content
  .popup-content {
    width: 95% !important;

    // input and textarea
    textarea,
    input {
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .banner {
    height: 71px !important;
    width: 81.4% !important;
    top: 53% !important;
    left: 7.9% !important;
  }

  .cover-img-logo {
    height: 42px !important;
    width: 42px !important;
    top: 24% !important;
    left: 7% !important;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    top: 35% !important;
    width: 88.5% !important;
    height: 85px !important;
    left: 6% !important;

    img {
      height: 129px !important;
    }
  }

  .cover-offer {
    bottom: 4% !important;
    right: 23px !important;
    width: 37% !important;
    padding: 5px !important;
    font-size: 5px !important;
    height: 23px !important;
  }

  .title-offer {
    top: 30% !important;
    font-size: 9px !important;
    background: #fff !important;
    width: 34% !important;
    left: 26% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 957px) {
  // Alphabet Filter
  .alphabet-filter {
    overflow-x: scroll;

    div {
      font-size: 11px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .input-range__text-steps {
    label {
      font-size: 11px;
      font-weight: bold;
      width: 33px;
    }
  }

  // Alphabet Filter
  .alphabet-filter {
    overflow-x: scroll;

    div {
      font-size: 11px !important;
    }
  }

  .title-job {
    h1 {
      font-size: 16px !important;
    }
  }

  .rate-part {
    button {
      padding: 0 36px !important;
    }
  }

  // popup-content
  .popup-content {
    // Calender and Channels
    .calender-channels {
      .tab-list-item-tab {
        padding: 10px 5px !important;
        font-size: 9px !important;
        box-shadow: 0px 3px 9px 0 rgba(19, 19, 20, 0.16) !important;
      }

      .tab-list-active-tab {
        margin: 4px !important;
        box-shadow: 0px 8px 12px 0 rgba(19, 19, 20, 0.16) !important;
      }
    }

    // circle container
    .circle__container {
      &__icon {
        padding: 10px 12px !important;
      }
    }
  }

  // Company Data
  .comp-img {
    order: 1;
  }

  .comp-input {
    width: 100%;
    order: 2;
  }

  // Media Mobile and desktop
  .cover-img.mobile-media {
    display: block !important;
    margin-bottom: 20px !important;

    img {
      width: 100% !important;
    }
  }

  .cover-img.desktop-media {
    display: none !important;
  }

  .cover-img {
    img {
      // width: 24% !important;
    }
  }

  .calender-channels {
    .tab-list-active-tab,
    .tab-list-item-tab {
      .bg-img {
        padding: 14px 12px;

        img {
          height: 17px !important;
          width: 12px !important;
        }
      }
    }
  }

  .CalendarDay {
    line-height: 0.9 !important;
    height: 33px !important;
  }
}

@media only screen and (min-width: 416px) and (max-width: 768px) {
  .banner {
    height: 180px !important;
    width: 80.5% !important;
    top: 53% !important;
    left: 8.3% !important;

    img {
      width: 100% !important;
    }
  }

  .cover-img-logo {
    height: 100px !important;
    width: 100px !important;
    top: 26% !important;
    left: 8.3% !important;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    top: 35% !important;
    width: 88.5% !important;
    /* height: 70px !important; */
    left: 5.9% !important;
    height: 329px !important;

    img {
      height: 329px !important;
    }
  }

  .cover-offer {
    bottom: 4.5% !important;
    right: 10.5% !important;
    width: 37% !important;
    text-align: center !important;
    padding: 3px !important;
    font-size: 21.5px !important;
    height: 57px !important;
    line-height: 1.9;
  }

  .title-offer {
    top: 31% !important;
    font-size: 5px !important;
    left: 26.9% !important;
    background: #fff !important;
    width: 26% !important;
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 416px) {
  .popup-content {
    .modal > .content {
      .gallery {
        img {
          height: 181px !important;
          width: 185px !important;
          margin-top: 5px !important;
        }
      }
    }
  }

  .banner {
    height: 101px !important;
    width: 82% !important;
    top: 53% !important;
    left: 7.4% !important;

    img {
      width: 100% !important;
    }
  }

  .cover-img-logo {
    height: 50px !important;
    width: 50px !important;
    top: 25% !important;
    left: 8% !important;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    top: 35% !important;
    width: 87.9% !important;
    height: 178px !important;
    left: 5.9% !important;

    img {
      height: 178px !important;
    }
  }

  .cover-offer {
    bottom: 4% !important;
    right: 10.5% !important;
    width: 37.3% !important;
    text-align: center !important;
    padding: 5px !important;
    font-size: 6px !important;
    height: 31px;
    line-height: 2.8;
  }

  .title-offer {
    top: 31% !important;
    font-size: 9px !important;
    left: 26.2% !important;
    background: #fff !important;
    width: 25% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .banner {
    height: 90px !important;
    width: 82% !important;
    top: 53% !important;
    left: 7.4% !important;

    img {
      width: 100% !important;
    }
  }

  .cover-img-logo {
    height: 45px !important;
    width: 45px !important;
    top: 25% !important;
    left: 8% !important;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    top: 35% !important;
    width: 87.9% !important;
    height: 178px !important;
    left: 5.9% !important;

    img {
      height: 156px !important;
    }
  }

  .cover-offer {
    bottom: 5% !important;
    right: 10.5% !important;
    width: 37.3% !important;
    text-align: center !important;
    padding: 5px !important;
    font-size: 6px !important;
    height: 31px;
    line-height: 2.8;
    height: 25px !important;
  }

  .title-offer {
    top: 31% !important;
    font-size: 9px !important;
    left: 26.2% !important;
    background: #fff !important;
    width: 36% !important;
  }
}

@media only screen and (max-width: 320px) {
  .banner {
    height: 70px !important;
    width: 82% !important;
    top: 53% !important;
    left: 7.4% !important;

    img {
      width: 100% !important;
    }
  }

  .cover-img-logo {
    height: 35px !important;
    width: 35px !important;
    top: 25% !important;
    left: 8% !important;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    top: 35% !important;
    width: 87.9% !important;
    height: 178px !important;
    left: 5.9% !important;

    img {
      height: 124px !important;
    }
  }

  .cover-offer {
    bottom: 3% !important;
    right: 10.5% !important;
    width: 37.3% !important;
    text-align: center !important;
    padding: 5px !important;
    font-size: 6px !important;
    height: 31px;
    line-height: 2.8;
    height: 25px !important;
  }

  .title-offer {
    top: 29% !important;
    font-size: 9px !important;
    left: 26.2% !important;
    background: #fff !important;
    width: 36% !important;
  }
}

/**************** Login Page *******************/

.left-half {
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50% !important;

    .content {
      padding: 50px !important;
    }
  }

  @media (max-width: 767px) {
    width: 100% !important;

    .content {
      padding: 50px !important;
    }
  }
}

.right-half {
  @media (max-width: 700px) {
    display: none;
  }
}

/**************** Time Line *******************/
.time-line {
  @media only screen and (max-width: 416px) {
    .content {
      display: none !important;
    }

    .active {
      display: block !important;

      .img-act {
        display: block !important;
        background: #2b65f9 !important;
        border-radius: 50% !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        -ms-border-radius: 50% !important;
        -o-border-radius: 50% !important;
        padding: 13px;
        box-shadow: 0 1.5px 22px -1px rgba(0, 0, 0, 0.3) !important;

        img {
          height: 25px !important;
          width: 25px !important;
        }
      }

      .img-d {
        display: none !important;
      }
    }

    .normal {
      display: block !important;

      .img-d {
        display: block !important;
        background: #fff;
        border: 1px solid #c1c0c0;
        border-radius: 50%;
        padding: 13px;

        img {
          height: 25px;
          width: 25px;
        }
      }

      .img-act {
        display: none !important;
      }
    }

    .arrow-up {
      display: none !important;
    }
  }

  @media (min-width: 380px) and (max-width: 415px) {
    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::after {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -147%;
      }
    }

    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::before {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -180%;
      }
    }
  }

  @media (min-width: 368px) and (max-width: 380px) {
    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::after {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -147%;
      }
    }

    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::before {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -148%;
      }
    }
  }

  @media (min-width: 360px) and (max-width: 368px) {
    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::after {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -147%;
      }
    }

    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::before {
        content: '';
        width: 96%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -147%;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 360px) {
    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::after {
        content: '';
        width: 135%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -133%;
      }
    }

    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::before {
        content: '';
        width: 85%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -131%;
      }
    }
  }

  @media (max-width: 320px) {
    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::after {
        content: '';
        width: 95%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -94%;
      }
    }

    li:nth-of-type(2n),
    li:nth-of-type(3n) {
      &::before {
        content: '';
        width: 147%;
        position: absolute;
        background: #0077c1;
        border: 1px solid #0077c1;
        top: 50%;
        z-index: 0;
        left: -186%;
        opacity: 0;
      }
    }
  }
}

/************ Calender Responsive **********/

.DayPicker__horizontal {
  @media (min-width: 416px) {
    .DayPicker_weekHeader {
      // width: 197%;
      // transform: translateX(-122px);

      li {
        // width: 73px !important;
      }
    }

    .CalendarMonth_table {
      // width: 500px !important;
      // border-spacing: 22px !important;
      // margin: auto;
    }

    .CalendarMonth {
      // width: 689px !important;
    }

    .DayPicker_transitionContainer {
      // width: 689px !important;
    }
  }

  @media only screen and (min-width: 414px) {
    .DayPickerNavigation_rightButton__horizontalDefault {
      // right: -36% !important;
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
      // left: -36% !important;
    }
  }

  @media only screen and (max-width: 416px) {
    .DayPicker_transitionContainer {
      width: 347px !important;
      transform: translateX(-18px) !important;
      -webkit-transform: translateX(-18px) !important;
      -moz-transform: translateX(-18px) !important;
      -ms-transform: translateX(-18px) !important;
      -o-transform: translateX(-18px) !important;
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
      right: 8px !important;
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
      left: 2% !important;
    }
  }

  @media only screen and (max-width: 360px) {
    .DayPickerNavigation_rightButton__horizontalDefault {
      right: 6px !important;
    }
  }

  @media only screen and (max-width: 320px) {
    .DayPicker_weekHeader {
      left: -22px !important;
    }

    .DayPicker_transitionContainer {
      width: 299px !important;

      .DayPicker_weekHeader {
        left: -22px !important;
      }
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
      right: 50px !important;
    }
  }
}

.rtl .DayPicker_transitionContainer {
  transform: translateX(181px) !important;
}

.rtl {
  @media only screen and (max-width: 416px) {
    .DayPicker_transitionContainer {
      width: 347px !important;
      transform: translateX(18px) !important;
    }
  }

  @media only screen and (max-width: 375px) {
    .DayPicker_transitionContainer {
      width: 277px !important;
      transform: translateX(18px) !important;
    }
  }

  @media only screen and (max-width: 360px) {
    .DayPicker_transitionContainer {
      width: 348px !important;
      transform: translateX(18px) !important;
    }
  }

  @media only screen and (max-width: 320px) {
    .DayPicker_transitionContainer {
      width: 299px !important;
      transform: translateX(18px) !important;
    }
  }
}
