/* globalStyles.css */

button {
  font-family: inherit;
}

@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/static/fonts/Tajawal-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal-bold';
  src: url('/static/fonts/Tajawal-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Twemoji Country Flags';
  unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067,
    U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
  src: url('/static/fonts/TwemojiCountryFlags.woff2') format('woff2');
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Albert Sans';
  overflow-x: hidden;
}
body:lang(ar) {
  font-family: 'Tajawal';
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Tajawal-bold';
  }
}

.full-content-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #131314;
}

input {
  font-family: inherit !important;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.arrow-down {
  display: block;
  margin-left: 8px;
  border: solid #0077c1;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(-2px) rotate(45deg);
  transition: 0.4s ease all;

  &.not-collapsed {
    transform: translateY(2px) rotate(-135deg);
  }
}

// Rtl Style
.rtl {
  direction: rtl;

  .arrow-down {
    margin-left: 0;
    margin-right: 10px;
  }

  .progress-bar {
    & > li:after {
      left: unset !important;
      right: 0 !important;
      margin-left: unset !important;
      margin-right: 25px;
    }
  }

  .pdf-reviewer {
    float: left !important;
  }

  .filters-tags {
    i {
      float: left !important;
    }
  }

  .section-part {
    h1 {
      text-align: right;
    }
  }

  .kRnKTO a span {
    margin-left: 0;
    margin-right: 13px;
  }

  li .arrow-up {
  }

  /* tabels in Index Home */

  table {
    text-align: right;

    .delete-btn {
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .partition {
    input[type='date'] {
      text-align: right;
      font-family: inherit;
    }
  }

  // Colaps
  .Collapsible__trigger:after {
    left: -33px !important;
    right: auto !important;
  }

  .ivLxS {
    bottom: 64px;
  }

  .ivLxS .circle-popup {
    box-shadow: 0 20px 43px 2px rgba(0, 65, 105, 0.2);
  }

  /* Input Data */
  .gmgfku input[type='date'] {
    text-align: right;
  }

  /* badged line */
  .eMPRUe {
    direction: ltr;
  }

  /* influencer card */

  .jfmbfO .rate-part button {
    padding: 0 38px;
  }

  /* Calender */

  .hFHRQd ul li .check,
  .hLwhhy ul li .check {
    right: 0;
    left: unset;
  }

  .popup-content .cRbdof {
    text-align: right !important;
  }

  .popup-content ul li label {
    text-align: right !important;
    margin-right: 5px;
  }

  .calender-channels .tab-list-tab {
    text-align: center !important;
    padding-right: 0 !important;
  }

  .calender-channels .bg-img {
    margin-right: 0 !important;
    margin-left: 15px;
  }

  .headline-icon {
    margin-left: 10px;
  }

  span .icon-stuff a img {
    padding-left: 5px;
  }

  .scroll-right {
    left: 0;
    right: unset !important;
    box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.05) !important;

    img {
      transform: rotate(180deg);
    }
  }

  #list-tabs {
    li {
      &:last-child {
        margin-right: unset !important;
        margin-left: 100px !important;
      }
    }
  }

  .custom-image {
    .circle-char,
    .circle-img {
      float: left !important;
    }
  }

  .circle {
    &__container {
      li {
        label {
          padding: 18px 62px 25px 62px;
        }

        .check {
          right: 20px;
          left: unset;
        }
      }
    }
  }

  .nav-tabs-influencers {
    li {
      padding-right: 2.5em;
      padding-left: unset;
    }
  }

  .file-select-name {
    left: unset !important;
    right: 4px;
  }

  .file-select-button {
    right: unset !important;
    left: 0;
  }

  .title-offer {
    left: 0% !important;
    background: none !important;
  }

  .time-line {
    // influencer timeline
    @media (min-width: 380px) and (max-width: 415px) {
      li:nth-of-type(3n) {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::after {
          content: '';
          width: 147%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -147%;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::before {
          content: '';
          width: 147%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -189%;
        }
      }
    }

    @media (min-width: 368px) and (max-width: 380px) {
      li:nth-of-type(3n) {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::after {
          content: '';
          width: 147%;
          position: absolute;
          background: #0077c1;
          border: 1px solid #0077c1;
          top: 50%;
          z-index: 0;
          left: -147%;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::before {
          content: '';
          width: 147%;
          position: absolute;
          background: #0077c1;
          border: 1px solid #0077c1;
          top: 50%;
          z-index: 0;
          left: -148%;
        }
      }
    }

    @media (min-width: 360px) and (max-width: 368px) {
      li:nth-of-type(3n) {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::after {
          content: '';
          width: 147%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -147%;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::before {
          content: '';
          width: 96%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -147%;
        }
      }
    }

    @media (min-width: 320px) and (max-width: 360px) {
      li:nth-of-type(3n) {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::after {
          content: '';
          width: 135%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -133%;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::before {
          content: '';
          width: 85%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -131%;
        }
      }
    }

    @media (max-width: 320px) {
      li:nth-of-type(3n) {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::after {
          content: '';
          width: 95%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -94%;
        }
      }

      li:nth-of-type(2n),
      li:nth-of-type(1n) {
        &::before {
          content: '';
          width: 147%;
          position: absolute;
          background: #2b65f9;
          border: 1px solid #2b65f9;
          top: 50%;
          z-index: 0;
          left: -186%;
          opacity: 0;
        }
      }
    }
  }

  .user {
    &--nav {
      &--info {
        text-align: left;
      }
    }
  }

  .imUTEC img {
    float: right;
  }

  .gynxbG {
    bottom: 94px;
  }

  .payment-container {
    text-align: right;
  }

  .section-search {
    img {
      content: '';
      position: absolute;
      right: 28px;
      bottom: 17px;
    }
  }
}

/**************** Time Line *******************/

// General  Style
.esyDrq .CalendarDay__blocked_out_of_range,
.esyDrq .CalendarDay__blocked_out_of_range:active,
.esyDrq .CalendarDay__blocked_out_of_range:hover {
  font-size: 16px;
}

input {
  outline: none;

  &:focus {
    outline: none;
  }
}

.fBkbhi button {
  border: none !important;
}

// Note
.ivLxS {
  position: fixed;
  left: 8px;
  bottom: 30px;
  right: auto;
  z-index: 9999;
}

// Payment
.payment-container {
  text-align: left;

  input {
    height: 40px;
    padding: 0 15px;
    font-size: 18px !important;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
  }

  .button-special {
    display: block;

    button {
      width: 100%;
      box-shadow: 0 20px 43px 2px rgba(0, 65, 105, 0.2);
    }
  }
}

// Issue style
.partition {
  label {
    font-weight: bold;
  }

  input {
    font-family: inherit;
  }
}

// Time line issue
.time-line {
  li {
    .content {
      font-size: 16px;
    }

    .arrow-up {
      top: 32px !important;
    }
  }
}

/********* Input Range ******/
.one {
  .input-range {
    width: 95% !important;
  }
}

.two {
  .input-range {
    width: 95% !important;
  }
}

.three {
  .input-range {
    width: 95.5% !important;
  }
}

/******** Influner Card *********/
.gallery-part {
  .influ-img {
    img {
      height: 196px;
      width: 100%;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
    }
  }
}

.content-influencer {
  position: relative;

  .rate-part {
    bottom: 0;
  }
}

.calender-channels {
  .tab-list-active-tab,
  .tab-list-item-tab {
    .bg-img {
      padding: 12px 11px;

      img {
        height: 22px;
        width: 18px;
      }
    }
  }
}

.popup-content .circle__container__icon {
  padding: 12px 16px !important;

  svg {
    display: block !important;
  }
}

// Cover img creat offer
.mobile-media {
  display: none;
}

.desktop-media {
  display: block;
}

.cover-img {
  position: relative;
  margin-bottom: 30%;

  img {
    height: 100%;
    width: 100%;
  }

  .banner {
    position: absolute;
    height: 96px;
    width: 81%;
    top: 53%;
    left: 9%;
    border: none;
    cursor: pointer;

    img {
      border-radius: 8px;
    }
  }

  .cover-img-logo {
    position: absolute;
    height: 53px;
    width: 53px;
    top: 25%;
    left: 8%;
    border-radius: 65px;
    z-index: 99;

    img {
      border-radius: 65px;
    }
  }

  .cover-img-cover {
    position: absolute;
    top: 35%;
    width: 87.5%;
    height: 119px;
    left: 6%;
    cursor: pointer;

    img {
      height: 176px;
    }
  }

  .cover-offer {
    background: black;
    position: absolute;
    bottom: 4%;
    right: 33px;
    width: 37%;
    height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 50px;
    padding: 6px;
    font-size: 10px;
  }

  .title-offer {
    position: absolute;
    top: 31%;
    font-size: 10px;
    left: 27%;
    background: #fff;
    width: 50%;
  }
}

.CalendarDay {
  line-height: 2.8;
  font-size: 14px;
}

// Style
.section-search {
  margin-bottom: 0px;
  position: relative;

  .search-tag {
    justify-content: center;
    margin-bottom: 7px;

    .search-input {
      margin: auto;
      position: relative;

      input {
        background-color: #ffffff !important;
        border-radius: 50px;
        box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 16px !important;
      }

      textarea {
        height: 40px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        width: 100%;
        resize: none;
        font-size: 14px !important;
        line-height: 16.38px;
        position: relative;
        padding: 11px 36px !important;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: -0.01em;
        border: 0px;
        font-family: Albert Sans;

        &:focus {
          outline-offset: 0 !important;
          outline: 0;
        }
      }

      img {
        content: '';
        position: absolute;
        left: 10px;
        bottom: 17px;
        width: 14px;
      }

      .drop-search {
        list-style: none;
        position: absolute;
        padding: 0;
        padding: 14px 0;
        margin: 0;
        z-index: 9999;
        width: 100%;
        background: #fff;
        box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.15);
        overflow-y: scroll;
        max-height: 50vh;

        ul {
          list-style: none;
          padding: 0;
        }

        .title-search {
          color: #9b9b9b;
          margin-top: 0;
          font-weight: 500;
        }

        li {
          cursor: pointer;
          text-transform: capitalize !important;
          padding: 7px 23px;
          font-weight: bold;
        }
      }
    }

    .tag-search {
      list-style: none;
      padding: 0px 5px;
      margin: 0;

      li {
        display: inline;
        cursor: pointer;
        margin: 2px 5px 2px 0;
        text-transform: capitalize !important;
        padding: 6px 20px 6px 6px;
        border-radius: 3px;
        line-height: 32px;
        font-size: 17px;
        transition: all 0.3s ease-in-out;
        background: #0077c1;
        color: #fff;
        border: none;

        span {
          padding: 0 13px 0 0;
          font-size: 17px;
          font-weight: 400;
        }

        i {
          font-weight: 100;
          font-style: normal;
        }

        &:hover {
          transition: all 0.3s ease-in-out;
          background: rgba(255, 255, 255, 0.6);
          color: #939398;
          border: none;
          border: 1.2px solid #e5e5ec;
        }
      }
    }
  }

  .overlay {
    display: none;
  }
}

.rtl .user {
  &--nav {
    &--info {
      text-align: left !important;
    }
  }
}

.section-search.show {
  .overlay {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000;
    z-index: 9994;
    top: 152px;
  }

  textarea {
    border: 2px solid #077bc2;
  }
}

// Dahbord Details
.hidden-profile-picture {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  margin-bottom: 13px;
  background: #f1f1f1;
  color: #a8a8a8;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 55px;
}

.dashbord-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #f8f8f8;
  box-shadow: 1px 2px 10px 0 rgba(126, 91, 1, 0.1);
  padding: 10px;

  .close-view-details {
    background: rgba(128, 128, 128, 0.27);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      top: 7px;
      left: 3px;
    }
  }

  .hidden-span {
    height: 100%;
    display: flex;

    span {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 10px 2px;
      font-size: 19px;
    }
  }

  .influencer-ad-request-date {
    position: absolute;
    margin: 6px -5px;
  }

  span {
    font-size: 12px;
    padding-left: 8px;
  }

  .clinet {
    position: relative;
    transition: transform 0.1s ease-out 0s;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;

    .date {
      line-height: 3;
      padding: 0px 12px;
      font-size: 13px;
    }

    img {
      border: 2px solid #fff;
      display: inline-block;
      position: relative;
      margin-left: -10px;
      vertical-align: top;
      z-index: 5;
      background: transparent;
      padding: 0px;
      transition: transform 0.1s ease-out 0s;
      border-radius: 50%;
      height: 44px;
      width: 44px;
      position: relative;
    }

    &:hover {
      transform: translateY(-4px);
      z-index: 9;

      .overlay {
        position: absolute;
        background: rgba(23, 23, 23, 0.22);
        display: inline-block;
        margin-left: -10px;
        vertical-align: top;
        z-index: 5;
        padding: 0px;
        transition: transform 0.1s ease-out 0s;
        border-radius: 50%;
        left: 0;
        left: 0px;
        height: 44px;
        width: 44px;
        border: 2px solid #fff;
      }
    }

    &:last-child {
      span {
        display: block;
        line-height: 2.3;
        text-align: center;
        margin: auto;
      }
    }
  }

  .clinet:first-child {
    margin-left: 0px;
  }
}

// CheckBox
.rtl .content-container ul li .checkbox.check {
  left: auto !important;
}

/****** CODE ******/

.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  position: relative;
  margin-top: 10px;
}

.file-upload .file-select {
  display: block;
  color: #34495e;
  cursor: pointer;
  /* height: 40px; */
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff !important;
}

.file-upload .file-select .file-select-button {
  background: #0177c0;
  padding: 0 10px;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  line-height: 4.5;

  img {
    height: 60%;
    margin-top: 10px;
  }

  @media only screen and (max-width: 320px) {
    display: none;
  }
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  position: absolute;
  line-height: 4.5;
  color: #9b9b9b;
  font-size: 13px;
  left: 5px;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type='file'] {
  height: 100%;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
  /* position: absolute; */
  opacity: 0;
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
