/* DatePicker.module.css */
.picker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-block-end: 2rem;
}

/* Hide the input field */
.form-control {
  display: none;
}

.flatpickr-calendar,
.flatpickr-innerContainer,
.flatpickr-rContainer,
.flatpickr-calendar,
.flatpickr-days {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
}

.flatpickr-current-month .numInputWrapper {
  display: none !important;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  border: 1px solid #c4c4c4;
  top: 5px !important;
}

.flatpickr-prev-month {
  left: 3px !important;
}

.flatpickr-next-month svg,
.flatpickr-prev-month svg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: -3px;
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block;
}

.flatpickr-next-month {
  right: 3px !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0077c1 !important;
}
.flatpickr-day.selected {
  background: #0077c1 !important;
  color: #fff !important;
  border: 1px solid #c4c4c4 !important;
}
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-monthDropdown-month,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #fff !important;
}
.flatpickr-months .flatpickr-month {
  margin-block-end: 1rem;
}
.flatpickr-current-month {
  color: #000 !important;
}
.dayContainer {
  @media (min-width: 600px) {
    width: 435px !important;
    min-width: 435px !important;
    max-width: 435px !important;
  }
}

.flatpickr-day {
  max-width: 100% !important;
}
.flatpickr-day.today {
  border: none !important;
}
/* hide green arrow */
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none;
}
